<template>
  <div class="moto-page">
    <div class="begin mt-6 text-black text-xl">
      <div class="title font-medium text-black text-4xl flex items-baseline">
        <div class="ic w-10">
          <svg-icon
            name="ic_moto_insurance"
            original
            class="w-8 h-8"
          />
        </div>
        <div class="title-text ml-4">{{ $t('motoInsurances') }}</div>
      </div><br>
      <div class="sous-title text-2xl">{{ $t('motoUse') }}</div><br>
      <div class="description leading-relaxed" v-html="$t('motoInsuranceDescription')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'moto-page',
  data () {
    return {
      guarantees: [
        {
          title: this.$t('civicResponsibility'),
          description: this.$t('responsibilityDescription')
        },
        {
          title: this.$t('theCEDEOA'),
          description: this.$t('cedeaoDescription')
        },
        {
          title: this.$t('complementaryGuarantees'),
          description: ''
        }
      ],
      necessaries: [
        {
          icon: 'ic_glass',
          name: this.$t('glass')
        },
        {
          icon: 'ic_thief',
          name: this.$t('thief')
        },
        {
          icon: 'ic_collision',
          name: this.$t('collision')
        },
        {
          icon: 'ic_flame',
          name: this.$t('fire')
        },
        {
          icon: 'ic_all_risk',
          name: this.$t('allRisk')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .sous-title {
    color: $aab_gray;
  }
  .svg-fill {
    fill: transparent;
  }
  .non-necessary {
    color: $aab_yellow;
  }
  .list {
    &:last-child {
      margin-right: 0;
    }
  }
  .icons {
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .radio {
    border-color: $aab_radio_color;
    padding: .35rem;
  }
  @media only screen and (max-width: 600px) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .bloc-list {
      flex-wrap: wrap;
    }
    .list {
      width: 45%;
      margin-bottom: 1.5rem;
    }
  }
</style>
